import memoizee from "memoizee";
import {useCallback, useEffect, useState} from "react";
import {Props} from "@page-containers/get-care/components/ListController";

const memoizedSlice = memoizee(<T>(list: T[], start: number, stop: number) =>
  list.slice(start, stop),
);

/**
 * This utility helps manage how many elements should be shown in the care-discovery list.
 *
 * @param list The full list of elements which could be rendered.
 * @param initialStop The number of elements which should be shown in the list initially.
 * @param onReset A callback executed when the user "resets" the list. The idea here is that
 *   a user can keep clicking "show more" until all the elements are shown, and then
 *   it becomes a "reset" button which shrinks the list back to its original size.
 *
 * @return Some Props which can be passed into the {@link ListController}, and the list slice
 *   which should be shown from the full list.
 */
export const useListBoundaryController = <T>(
  list: T[],
  initialStop: number,
  onReset: () => unknown,
): Props & {boundList: T[]} => {
  const listLength = list.length;
  const getInitialBoundaries = useCallback((): [number, number] => [0, initialStop], [initialStop]);

  const [[start, stop], setRenderBoundaries] = useState(getInitialBoundaries());

  useEffect(() => {
    setRenderBoundaries(getInitialBoundaries());
  }, [getInitialBoundaries]);

  const showMore = useCallback(() => {
    setRenderBoundaries([start, Math.min(stop + 10, listLength)]);
  }, [listLength, start, stop]);

  const reset = useCallback(() => {
    setRenderBoundaries(getInitialBoundaries());
  }, [getInitialBoundaries]);

  const isMaxed = stop >= listLength;
  const handleShowMoreClick = useCallback(() => {
    if (isMaxed) {
      reset();
      onReset();
    } else {
      showMore();
    }
  }, [isMaxed, reset, onReset, showMore]);

  const boundList = listLength >= 7 ? memoizedSlice(list, start, stop) : list;
  return {boundList, start, stop, isMaxed, handleShowMoreClick, total: listLength};
};
